import { combineReducers } from "redux";
import HomeReducer from "./Home/homeReducers";
import ProductsReducer from "./Products/productsReducer";
import StaticReducer from "./StaticPages/staticReducer";

const RootReducer = combineReducers({
  home: HomeReducer,
  products: ProductsReducer,
  static: StaticReducer,
});

export default RootReducer;
