import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./plastFreeStats.css";

const PlastFreeStats = () => {
  const { plastfreeRevolution } = useSelector(state => state.products);
  const [toolTipPos, setToolTipPos] = useState({
    right: "50%",
  });
  const [toolTipArrow, setToolTipArrow] = useState(1);
  const toolTipRef = useRef();

  const onMouseMove = event => {
    if (event.screenX > 400) {
      setToolTipPos({
        right: "50%",
      });
      setToolTipArrow(1);
    } else {
      setToolTipPos({
        left: "50%",
      });
      setToolTipArrow(-1);
    }
  };

  return (
    <div className="plastFree-stats-container">
      <h2>Why join the plastfree revolution?</h2>
      <div ref={toolTipRef} className="plastFree-stats">
        {plastfreeRevolution.map(stat => (
          <div onMouseMove={event => onMouseMove(event)} key={stat.id}>
            <span>{stat.title}</span>
            <span>{stat.circle_content}</span>
            <em
              className={
                toolTipArrow === 1
                  ? "plastFree-stats-tooltip"
                  : "plastFree-stats-tooltip-al"
              }
              style={toolTipPos}
            >
              {stat.toltip_content}
            </em>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlastFreeStats;
