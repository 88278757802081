import React from "react";

const Star = ({ filled, filledColor }) => {
  return filled ? (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="star"
    >
      <path
        d="M11.433 13.9964C11.3153 13.9969 11.1991 13.9691 11.0943 13.9154L7.33814 11.9489L3.58203 13.9154C3.46006 13.9795 3.32254 14.0081 3.18511 13.998C3.04768 13.9879 2.91585 13.9394 2.80461 13.8581C2.69336 13.7767 2.60716 13.6658 2.55581 13.538C2.50446 13.4101 2.49001 13.2704 2.51411 13.1347L3.25061 8.98824L0.216256 6.04227C0.121586 5.94779 0.0544278 5.82932 0.0219898 5.69957C-0.0104481 5.56982 -0.00694163 5.43368 0.032133 5.30577C0.07482 5.17488 0.153342 5.05857 0.258789 4.97005C0.364235 4.88153 0.492384 4.82433 0.628692 4.80496L4.8267 4.19367L6.6753 0.415463C6.7356 0.290943 6.82976 0.185929 6.947 0.11245C7.06423 0.0389717 7.19978 0 7.33814 0C7.47649 0 7.61205 0.0389717 7.72928 0.11245C7.84651 0.185929 7.94067 0.290943 8.00098 0.415463L9.87167 4.1863L14.0697 4.79759C14.206 4.81697 14.3341 4.87416 14.4396 4.96268C14.545 5.05121 14.6236 5.16751 14.6662 5.29841C14.7053 5.42632 14.7088 5.56245 14.6764 5.69221C14.6439 5.82196 14.5768 5.94043 14.4821 6.0349L11.4478 8.98087L12.1843 13.1273C12.2106 13.2654 12.1968 13.4081 12.1446 13.5386C12.0924 13.6691 12.0039 13.782 11.8897 13.8638C11.7563 13.9573 11.5957 14.0039 11.433 13.9964Z"
        fill={filledColor}
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="star"
    >
      <path
        d="M12.2265 13.9964C12.1087 13.9969 11.9925 13.9691 11.8877 13.9154L8.1316 11.9489L4.37548 13.9154C4.25352 13.9795 4.116 14.0081 3.97857 13.998C3.84114 13.9879 3.70931 13.9394 3.59806 13.8581C3.48682 13.7767 3.40062 13.6658 3.34927 13.538C3.29791 13.4101 3.28347 13.2704 3.30757 13.1347L4.04406 8.98824L1.00971 6.04227C0.915043 5.94779 0.847885 5.82932 0.815447 5.69957C0.783009 5.56982 0.786515 5.43368 0.82559 5.30577C0.868277 5.17488 0.946799 5.05857 1.05225 4.97005C1.15769 4.88153 1.28584 4.82433 1.42215 4.80496L5.62016 4.19367L7.46875 0.415463C7.52906 0.290943 7.62322 0.185929 7.74045 0.11245C7.85768 0.0389717 7.99324 0 8.1316 0C8.26995 0 8.40551 0.0389717 8.52274 0.11245C8.63997 0.185929 8.73413 0.290943 8.79444 0.415463L10.6651 4.1863L14.8631 4.79759C14.9994 4.81697 15.1276 4.87416 15.233 4.96268C15.3385 5.05121 15.417 5.16751 15.4597 5.29841C15.4988 5.42632 15.5023 5.56245 15.4698 5.69221C15.4374 5.82196 15.3702 5.94043 15.2756 6.0349L12.2412 8.98087L12.9777 13.1273C13.004 13.2654 12.9902 13.4081 12.938 13.5386C12.8858 13.6691 12.7974 13.782 12.6831 13.8638C12.5498 13.9573 12.3892 14.0039 12.2265 13.9964Z"
        fill="#CEB4AD"
      />
    </svg>
  );
};

export default Star;
