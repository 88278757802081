import React from "react";
import SVGIcons from "../../../../Shared/SVGIcons/SVGIcons";
import "./customBoxItem.css";

const CustomBoxItem = () => {
  return (
    <div className="custom-box-item">
      <span className="custom-box-item-title">Tampon(R)</span>
      <div className="custom-box-item-count-container">
        <span className="custom-box-item-count">2 PACK</span>
        <SVGIcons onClick={() => {}} icon="custom_cart_plus" />
        <SVGIcons onClick={() => {}} icon="custom_cart_minus" />
      </div>
    </div>
  );
};

export default CustomBoxItem;
