import React from "react";
import { useSelector } from "react-redux";
import "./OurCommitment.css";

const OurCommitment = () => {
  const { commitments } = useSelector(state => state.home);

  return (
    <div className="commitment-container">
      <h1>Our Commitment</h1>
      <div className="commitments-list-container">
        {commitments.map(commitment => (
          <div key={commitment.id} className="commitment-item">
            <img src={commitment.image} alt={commitment.title} />
            <h2>{commitment.title}</h2>
            <p>{commitment.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurCommitment;
