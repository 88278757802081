import {
  BANNERS,
  CERTIFICATES,
  CONTACT,
  FAQ,
  FOUNDATION,
  GOAL,
  MISSION,
  POLICY,
  PRESS,
  STATIC_MODAL,
  STORY,
  TERMS,
} from "./staticActionType";

const initialState = {
  static_modal: { bool: false, content: {} },
  certificates: [],
  contact: {},
  faq: [],
  press: [],
  terms: {},
  privacy: {},
  goals: [],
  missions: [],
  story: {},
  foundation: [],
  banners: [],
};

const StaticReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATIC_MODAL: {
      return {
        ...state,
        static_modal: { bool: action.bool, content: action.content },
      };
    }
    case CERTIFICATES: {
      return {
        ...state,
        certificates: action.payload,
      };
    }
    case CONTACT: {
      return {
        ...state,
        contact: action.payload,
      };
    }
    case FAQ: {
      return {
        ...state,
        faq: action.payload,
      };
    }
    case PRESS: {
      return {
        ...state,
        press: action.payload,
      };
    }
    case TERMS: {
      return {
        ...state,
        terms: action.payload,
      };
    }
    case POLICY: {
      return {
        ...state,
        privacy: action.payload,
      };
    }
    case GOAL: {
      return {
        ...state,
        goals: action.payload,
      };
    }
    case MISSION: {
      return {
        ...state,
        missions: action.payload,
      };
    }
    case STORY: {
      return {
        ...state,
        story: action.payload,
      };
    }
    case FOUNDATION: {
      return {
        ...state,
        foundation: action.payload,
      };
    }
    case BANNERS: {
      return {
        ...state,
        banners: action.payload,
      };
    }

    default:
      return state;
  }
};

export default StaticReducer;
