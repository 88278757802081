import React from "react";
import { useSelector } from "react-redux";
import "./faqBanner.css";
import SVGIcons from "../../../Shared/SVGIcons/SVGIcons";

const FAQBanner = ({ mainRef }) => {
  const { banners } = useSelector(state => state.static);
  const faqBanner = banners.find(e => e.title === "FAQ");
  const {
    title,
    image,
    description = "",
  } = faqBanner !== undefined ? faqBanner : {};

  const modifiedDescription = description.split(".");

  const scrollToMain = () => {
    mainRef.current.scrollIntoView();
  };

  return (
    <div className="faq-banner-container">
      <img src={image} alt={title} />
      <div className="faq-banner-content">
        <h1>{title}</h1>
        <p>{`${modifiedDescription[0]}.`}</p>
        <span className="faq-contact-info">{modifiedDescription[1]}</span>
        <SVGIcons onClick={scrollToMain} icon="down_chevron" />
      </div>
    </div>
  );
};

export default FAQBanner;
