import React from "react";
import "./PFStatement.css";
import { useSelector } from "react-redux";
import { PFButton } from "../../../Shared/Components/PFButton";
import SVGIcons from "../../../Shared/SVGIcons/SVGIcons";

const PFStatement = () => {
  const { statement } = useSelector(state => state.home);
  const { content = "", title = "", video = "" } = statement;

  const IFrame = () => (
    <div
      className="statement-video-container"
      dangerouslySetInnerHTML={{ __html: video }}
    />
  );

  return (
    <div className="statement-container">
      <div className="statement-content-container">
        <div>
          <div>PLASTFREE STATEMENT</div>
          <h1>{title}</h1>
          <p>{content}</p>
          <div className="btn-container">
            <PFButton text="LEARN MORE" />
          </div>
          <div className="cotton-vector-container1">
            <SVGIcons icon={"cottonVector1"} />
          </div>
          <div className="cotton-vector-container2">
            <SVGIcons icon={"cottonVector2"} />
          </div>
        </div>
      </div>
      <IFrame />
    </div>
  );
};

export default PFStatement;
