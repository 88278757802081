import React from "react";
import { useSelector } from "react-redux";
import "./certificationsMain.css";

const CertificationsMain = ({ mainRef }) => {
  const { certificates } = useSelector(state => state.static);

  const Certificates = props => {
    const { title = "", content = "", certify_image = "" } = props;

    return (
      <div className="certificate-container">
        <img src={certify_image} />
        <div className="certificate-content">
          <span>{title}</span>
          <p>{content}</p>
        </div>
      </div>
    );
  };

  return (
    <div ref={mainRef} className="certifications-main-container">
      <p>
        Plastfree Sanitary Pads are third party tested for manufacturing,
        performance & absorption.
      </p>
      {certificates.map(certificate => (
        <Certificates {...certificate} />
      ))}
    </div>
  );
};

export default CertificationsMain;
