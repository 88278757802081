import BlogCover from "../../../Assets/blogcover.png";

export const blogData = [
  {
    id: 0,
    image: BlogCover,
    title:
      "Plastfree supports homeless charity to tackle period poverty in London.",
    desc:
      "Plastfree is eager to support them in their journey by donating period products as often as needed and started off the partnership by donating 100 boxes to the charity. With increasing number of people affected by job losses and reaching out to food banks and shelters, it is ever more important to support our community and live consciously!!",
  },
  {
    id: 1,
    image: BlogCover,
    title:
      "Plastfree supports homeless charity to tackle period poverty in London.",
    desc:
      "Plastfree is eager to support them in their journey by donating period products as often as needed and started off the partnership by donating 100 boxes to the charity. With increasing number of people affected by job losses and reaching out to food banks and shelters, it is ever more important to support our community and live consciously!!",
  },
  {
    id: 2,
    image: BlogCover,
    title:
      "Plastfree supports homeless charity to tackle period poverty in London.",
    desc:
      "Plastfree is eager to support them in their journey by donating period products as often as needed and started off the partnership by donating 100 boxes to the charity. With increasing number of people affected by job losses and reaching out to food banks and shelters, it is ever more important to support our community and live consciously!!",
  },
];
