import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  LeftPane,
  MyProfile,
  MyProfileEdit,
  OrderDetails,
  OrderHistory,
} from "../../Components/Account";
import { PANE_TYPES } from "./paneTypes";
import "./account.css";

const Profile = () => {
  const location = useLocation();
  const [activePane, setActivePane] = useState(PANE_TYPES.MY_PROFILE);

  useEffect(() => {
    const path = location.pathname.replace("/", "");
    path === "my-profile" && setActivePane(PANE_TYPES.MY_PROFILE);
    path === "my-orders" && setActivePane(PANE_TYPES.ORDER_HISTORY);
  }, []);

  const MainPanel = () => {
    return activePane === 0 ? (
      <MyProfile setActivePane={setActivePane} />
    ) : activePane === 1 ? (
      <MyProfileEdit setActivePane={setActivePane} />
    ) : activePane === 2 ? (
      <OrderHistory setActivePane={setActivePane} />
    ) : (
      activePane === 3 && <OrderDetails setActivePane={setActivePane} />
    );
  };

  return (
    <main className="profile-container">
      <LeftPane setActivePane={setActivePane} />
      <MainPanel />
    </main>
  );
};

export default Profile;
