import React from "react";
import { useSelector } from "react-redux";
import "./ingredients.css";

const Ingredients = () => {
  const { ourIngredients: ingredients } = useSelector(state => state.products);

  return (
    <div className="ingredients-container">
      <h2>Our Ingredients</h2>
      <div className="ingredients-icons">
        {ingredients.map(content => (
          <div>
            <img src={content.image} />
            <span>{content.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ingredients;
