import React, { useEffect, useState } from "react";
import "./contactUsMain.css";

const ContactUsMain = ({
  mainRef,
  career_inquiries = "",
  general_inquiries = "",
  press_inquiries = "",
}) => {
  const [enquiries, setEnquiries] = useState([]);

  useEffect(() => {
    setEnquiries([
      { title: "General Enquiries", desc: general_inquiries },
      { title: "Career Enquiries", desc: career_inquiries },
      { title: "Press Enquiries", desc: press_inquiries },
    ]);
  }, [career_inquiries, general_inquiries, press_inquiries]);

  return (
    <div ref={mainRef} className="contact-us-main-container">
      {enquiries.map((e, i) => (
        <div className="contact-us-main-content">
          <div className="contact-us-main-content-child">
            <h2>{e.title}</h2>
            <span>{e.desc}</span>
          </div>
          {i !== 2 && <div className="contact-us-separator" />}
        </div>
      ))}
    </div>
  );
};

export default ContactUsMain;
