import {
  addAmbassador,
  addCommitments,
  addMediaPartners,
  addPartners,
  addPlastfreeStatement,
  addTestimonials,
} from "../Redux/Home/homeActions";
import { PfGET } from "./APIConfig";
import { WpVersion } from "./APIConfig";

export const getMediaPartners = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/mediapartners`);
    dispatch(addMediaPartners(data));
  };
};

export const getStatement = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/plastfreestatement`);
    const statement = data[0];
    dispatch(addPlastfreeStatement(statement));
  };
};

export const getTestimonials = () => {
  return async (dispatch, getState) => {
    const {
      home: { testimonials },
    } = getState();

    const { data } = testimonials;

    if (data.length === 0) {
      const data = await PfGET(`${WpVersion.v1}/testimonial`);
      dispatch(addTestimonials(data));
    }
  };
};

export const getPartners = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/partners`);
    dispatch(addPartners(data));
  };
};

export const getAmbassador = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/plastfreeambassador`);
    const ambassador = data[0];
    dispatch(addAmbassador(ambassador));
  };
};

export const getCommitments = () => {
  return async (dispatch, getState) => {
    const {
      home: { commitments },
    } = getState();
    if (commitments.length === 0) {
      const data = await PfGET(`${WpVersion.v1}/ourcommittment`);
      Array.isArray(data) && dispatch(addCommitments(data));
    }
  };
};
