import React from "react";
import SVGIcons from "../../SVGIcons/SVGIcons";

const SocialIcons = () => {
  return (
    <div className="social-icons-container">
      <SVGIcons icon="facebookIcon" />
      <SVGIcons icon="instaIcon" />
      <SVGIcons icon="pinterestIcon" />
      <SVGIcons icon="youtubeIcon" />
      <SVGIcons icon="twitterIcon" />
    </div>
  );
};

export default SocialIcons;
