import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PFButton } from "../../../Shared/Components/PFButton";
import {
  RoundLeftArrow,
  RoundRightArrow,
} from "../../../Shared/SVGIcons/CustomSVG";
import "./testimonials.css";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { RatingStars } from "../../../Shared/Components/RatingStars";

const Testimonials = () => {
  const { testimonials } = useSelector(state => state.home);
  const { ratings = 0, data = [] } = testimonials;

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <span
        style={{ position: "absolute", right: 0, cursor: "pointer" }}
        onClick={onClick}
      >
        <RoundRightArrow />
      </span>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <span
        style={{
          position: "absolute",
          left: 0,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <RoundLeftArrow />
      </span>
    );
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="testimonials-container">
      <h2>TESTIMONIALS</h2>
      <div className="testimonials-ratings">
        <RatingStars count={ratings} />
        <span className="testimonials-ratings-count">
          ({data.length} REVIEWS)
        </span>
      </div>
      <div className="reviews-container">
        <MultiCarousel
          customRightArrow={<NextArrow />}
          customLeftArrow={<PrevArrow />}
          responsive={responsive}
          infinite
        >
          {data.map(
            testimonial =>
              testimonial.status === "publish" && (
                <div key={testimonial.id} className="review-content">
                  <p className="review-desc">
                    “{testimonial.content.substr(0, 200)}...”
                  </p>
                  <h3 className="review-author">- {testimonial.title} -</h3>
                </div>
              )
          )}
        </MultiCarousel>
      </div>
      <PFButton text="SEE ALL CUSTOMER REVIEWS" />
    </div>
  );
};

export default Testimonials;
