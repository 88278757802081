import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ResetPassword, SignInForm, SignUpForm } from "../../Components/Auth";
import AuthLayout from "../../Components/Auth/Layout";

const AuthFormPage = () => {
  const location = useLocation();
  const pathName = location.pathname.replace("/", "");

  const { banners } = useSelector(state => state.static);

  const signUpCover = banners.find(e => e.id === 3263);
  const signInCover = banners.find(e => e.id === 3262);
  const { description = "", image = "" } =
    signUpCover !== undefined ? signUpCover : {};

  const { description: signInDescription, image: signInImage } =
    signInCover !== undefined ? signInCover : {};

  return pathName === "sign-up" ? (
    <AuthLayout Title={description} Form={SignUpForm} Cover={image} />
  ) : pathName === "sign-in" ? (
    <AuthLayout
      Title={signInDescription}
      Form={SignInForm}
      Cover={signInImage}
    />
  ) : (
    <AuthLayout Title={"Reset your password."} Form={ResetPassword} />
  );
};

export default AuthFormPage;
