import React from "react";
import { PFButton } from "../../../Shared/Components/PFButton";
import "./oneOffPurchase.css";
import { ProductDetails } from "./ProductDetails";
import { ProductImageCarousel } from "./ProductImageCarousel";

const OneOffPurchase = () => {
  const products = [
    { name: "liner" },
    { name: "tampon" },
    { name: "pad" },
    { name: "maternity" },
    { name: "period kit" },
  ];

  return (
    <div className="one-off-purchase-container">
      <h1>One-Off Purchase</h1>
      <div className="one-off-purchase-products-container">
        {products.map(v => (
          <div className="one-off-purchase-products-category-button">
            <PFButton borderLess text={v.name} />
          </div>
        ))}
      </div>
      <div className="one-off-purchase-main">
        <div className="one-off-purchase-images-container">
          <ProductImageCarousel />
        </div>
        <div className="one-off-purchase-details-container">
          <ProductDetails />
        </div>
      </div>
    </div>
  );
};

export default OneOffPurchase;
