import React from "react";
import { useSelector } from "react-redux";
import "./goal.css";

const Goal = () => {
  const { goals } = useSelector(state => state.static);

  return (
    <div className="goal-container">
      <h3>Our Goal</h3>
      <div className="goals">
        {goals.map(goal => (
          <div key={goal.id} className="goal">
            <img src={goal.image} />
            <p>{goal.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Goal;
