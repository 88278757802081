import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { FAQBanner, FAQMain } from "../../../Components/Static/FAQ";
import { getFAQ } from "../../../Shared/Services/staticPages";
import "./faq.css";

const FAQ = () => {
  const mainRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFAQ());
  }, []);

  return (
    <div>
      <FAQBanner mainRef={mainRef} />
      <FAQMain mainRef={mainRef} />
    </div>
  );
};

export default FAQ;
