const formats = {
  email:
    /^[_a-z0-9-]+(.[_a-z0-9-\+]+)+(.[_a-z0-9-\+]+)@[a-z0-9-]+(.[a-z0-9-]+)(.[a-z]{2,3})$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  text: /^[a-zA-Z\s]*$/,
  mobile: /^[0-9]*$/,
};

export const regexTest = (type, text) => formats[type].test(text);

export const getSanitizedUserName = email => {
  const emailFirstPart = email.split("@")[0];
  const CapitalizedName =
    emailFirstPart.charAt(0).toUpperCase() + emailFirstPart.slice(1);
  const username = CapitalizedName.replace(/[^A-Za-z]+/g, "");
  return username;
};
