import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AboutBanner } from "../../../Components/Static/AboutUs/Banner";
import { Foundation } from "../../../Components/Static/AboutUs/Foundation";
import { OurGoal } from "../../../Components/Static/AboutUs/Goal";
import { OurMission } from "../../../Components/Static/AboutUs/Mission";
import { OurStory } from "../../../Components/Static/AboutUs/Story";
import {
  getFoundation,
  getGoals,
  getMissions,
  getStory,
} from "../../../Shared/Services/staticPages";
import "./aboutUs.css";

const AboutUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStory());
    dispatch(getFoundation());
    dispatch(getGoals());
    dispatch(getMissions());
  }, []);

  return (
    <div>
      <AboutBanner />
      <OurStory />
      <OurGoal />
      <OurMission />
      <Foundation />
    </div>
  );
};

export default AboutUs;
