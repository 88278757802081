import React from "react";
import "./layout.css";

const AuthLayout = ({ Title, Form, Cover }) => {
  const formClass = Cover
    ? "auth-layout-title-container"
    : "auth-layout-title-container-no-cover";

  return (
    <div>
      <section className="auth-layout-container">
        <div className={formClass}>
          {Cover && <img alt={Title} src={Cover} />}
          <div className="auth-layout-title">
            <h1>{Title}</h1>
          </div>
        </div>
        {!Cover && <div className="auth-layout-separator"></div>}
        <div className="auth-layout-form-container">
          <Form />
        </div>
      </section>
    </div>
  );
};

export default AuthLayout;
