import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SVGIcons from "../../../Shared/SVGIcons/SVGIcons";
import "./faqMain.css";

const FAQMain = ({ mainRef }) => {
  const { faq } = useSelector(state => state.static);
  const leftPanelSections = [...new Set(faq.map(faq => faq.title))];
  const [activeQA, setActiveQA] = useState({});
  const [activeQ, setActiveQ] = useState(0);

  const addFaq = title => {
    const filteredQA = faq.filter(
      qa => qa.title.toLowerCase() === title.toLowerCase()
    );
    setActiveQA({ title, data: filteredQA });
  };

  const addActiveQuestion = id => {
    id === activeQ ? setActiveQ(0) : setActiveQ(id);
  };

  useEffect(() => {
    faq.length > 0 && addFaq(faq[0]?.title);
  }, [faq]);

  return (
    <div ref={mainRef} className="faq-main-container">
      <div className="faq-main-left-panel-container">
        {leftPanelSections.map(title => (
          <div onClick={() => addFaq(title)} key={title}>
            <span
              className={
                activeQA.title === title
                  ? "faq-section-title-black"
                  : "faq-section-title"
              }
            >
              {title}
            </span>
            <SVGIcons icon="right_chevron" />
          </div>
        ))}
      </div>
      <div className="faq-main-content-container">
        <h2>{activeQA.title?.toLowerCase()}</h2>
        {activeQA.data?.map(qa => (
          <div key={qa.id}>
            <div className="faq-ques-ans-holder">
              <SVGIcons
                onClick={() => addActiveQuestion(qa.id)}
                icon="down_chevron_2"
              />
              <div>
                <span
                  onClick={() => addActiveQuestion(qa.id)}
                  className="faq-ques"
                >
                  {qa.question}
                </span>
                {activeQ === qa.id && (
                  <span className="faq-ans">{qa.answer}</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQMain;
