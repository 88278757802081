import {
  COMMITMENTS,
  MEDIA_PARTNERS,
  PARTNERS,
  PLASTFREE_AMBASSADOR,
  PLASTFREE_STATEMENT,
  TESTIMONIALS,
} from "./homeActionTypes";

const initialState = {
  testimonials: { ratings: 0, data: [] },
  partners: [],
  commitments: [],
  statement: {},
  ambassador: {},
  media: [],
};

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_PARTNERS: {
      return {
        ...state,
        media: action.payload,
      };
    }
    case PLASTFREE_STATEMENT: {
      return {
        ...state,
        statement: action.payload,
      };
    }
    case TESTIMONIALS: {
      return {
        ...state,
        testimonials: action.payload,
      };
    }
    case PLASTFREE_AMBASSADOR: {
      return {
        ...state,
        ambassador: action.payload,
      };
    }
    case COMMITMENTS: {
      return {
        ...state,
        commitments: action.payload,
      };
    }
    case PARTNERS: {
      return {
        ...state,
        partners: action.payload,
      };
    }

    default:
      return state;
  }
};

export default HomeReducer;
