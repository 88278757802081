import React from "react";
import SVGIcons from "../../SVGIcons/SVGIcons";
import "./OffHeader.css";

const OffHeader = () => {
  return (
    <div class="top-header">
      <span />
      <p>
        Sign up for 25% OFF on your first purchase. Code:{" "}
        <span>PLASTFREE25</span>
      </p>
      <SVGIcons icon={"xClose"} />
    </div>
  );
};

export default OffHeader;
