import React from "react";
import { useSelector } from "react-redux";
import "./story.css";

const Story = () => {
  const { story } = useSelector(state => state.static);
  const { title = "", content = "", image = "" } = story;

  return (
    <div className="about-story-container">
      <div className="about-story-left-container">
        <span>PLASTFREE UK</span>
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
      <div className="about-story-right-container">
        <img src={image} alt={title} />
      </div>
    </div>
  );
};

export default Story;
