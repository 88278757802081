import React from "react";
import "./padDifference.css";
import SVGIcons from "../../../../Shared/SVGIcons/SVGIcons";

const PadDifference = () => {
  return (
    <div className="pad-difference-container">
      <div className="conventional-pads-container">
        <h2>Conventional Pads</h2>
        <div>
          <SVGIcons icon="cert_right_arrow" />
          <span>
            <strong>PLASTIC</strong> Average pad contains 90% plastic, also the
            disposal covers and packaging
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />

          <span>
            <strong> PERFUME & DYES</strong> are added to eliminate odour but
            harming the vaginal area causing irritation
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />

          <span>
            <strong>SUPER ABSORBENT GELS</strong> are Polymers made from crude
            oil to absorb blood flow
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />{" "}
          <span>
            {" "}
            <strong>CHLORINE</strong> is used to bleach the layers that emits
            Dioxin
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />

          <span>
            {" "}
            <strong>BACK LAYER</strong> pads have a plastic back layer to
            prevent leakage
          </span>
        </div>
      </div>
      <div className="plastfree-pads-container">
        <h2>Plastfree Pads</h2>
        <div>
          <SVGIcons icon="cert_right_arrow" />
          <span>
            {" "}
            <strong>NO PLASTIC</strong> Our pads are made with GOTS Organic
            Cotton top Sheet, Sustainable Core and Cotton Disposal Covers
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />

          <span>
            {" "}
            <strong>NO PERFUMES & DYES</strong> No unnecessary perfume is added
            to cause skin irritation
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />

          <span>
            {" "}
            <strong>BIO-BASED SAP</strong> Made from wood pulp and other
            biobased ingredients giving highest absorption
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />

          <span>
            {" "}
            <strong>NO CHLORINE</strong> Totally chlorine free
          </span>
        </div>
        <div>
          <SVGIcons icon="cert_right_arrow" />{" "}
          <span>
            {" "}
            <strong>BACK LAYER</strong> Biodegradable bottom layer ensuring no
            leakage
          </span>
        </div>
      </div>
    </div>
  );
};

export default PadDifference;
