import React from "react";
import { useHistory, useLocation } from "react-router";
import "./Header.css";

const NavMenu = props => {
  const { home } = props;
  const location = useLocation();
  const history = useHistory();
  const pathName = location.pathname.replace("/", "");

  const navigate = path => history.push(path);

  return (
    <ul className={home ? "nav-menu-container-home" : "nav-menu-container"}>
      <li
        onClick={() => navigate("about-us")}
        className={pathName === "about-us" ? "underlined-nav" : ""}
      >
        ABOUT US
      </li>
      <li
        onClick={() => navigate("products")}
        className={pathName === "products" ? "underlined-nav" : ""}
      >
        PRODUCTS
      </li>
      <li className={pathName === "voucher" ? "underlined-nav" : ""}>
        VOUCHER
      </li>
      <li
        onClick={() => navigate("faq")}
        className={pathName === "faq" ? "underlined-nav" : ""}
      >
        FAQ
      </li>
    </ul>
  );
};

export default NavMenu;
