import React, { useState } from "react";
import "./myProfileEdit.css";
import { FormInput } from "../../Shared/Components/FormInput";
import { PFButton } from "../../Shared/Components/PFButton";

const MyProfileEdit = () => {
  const [profileForm, setProfileForm] = useState({});

  const onInputChange = event => {
    const { id = "", value = "" } = event.target;
    setProfileForm({ ...profileForm, [id]: value });
  };

  return (
    <section className="my-profile-edit-container">
      <h1>My Profile</h1>
      <form className="my-profile-edit-form">
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Your First Name"
            name="First Name"
            id="first_name"
            value={profileForm.first_name}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Your Last Name"
            name="Last Name"
            id="last_name"
            value={profileForm.last_name}
            onChange={onInputChange}
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="email"
            placeholder="Your Email"
            name="Email"
            id="email"
            value={profileForm.email}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Your Phone Number"
            name="Phone Number"
            id="phone"
            value={profileForm.phone}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Enter Your Address"
            name="Address"
            id="address_2"
            value={profileForm.address_1}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Enter Your Address"
            name="Apartment , suite, etc"
            id="address_2"
            value={profileForm.address_2}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Enter Your City"
            name="City"
            id="city"
            value={profileForm.city}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Enter Your Postal Code"
            name="Postal / Zip code"
            id="postcode"
            value={profileForm.postcode}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="password"
            placeholder="Enter your password"
            name="Password"
            id="password"
            value={profileForm.password}
            onChange={onInputChange}
            required
          />
        </div>
        <div className="profile-form-input-holder">
          <FormInput
            type="text"
            placeholder="Confirm your password"
            name="Confirm Password"
            id="con_password"
            value={profileForm.con_password}
            onChange={onInputChange}
            required
          />
        </div>
      </form>
      <div className="profile-edit-save-holder">
        <PFButton text="Save" />
        <span>Cancel</span>
      </div>
    </section>
  );
};

export default MyProfileEdit;
