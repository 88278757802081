import {
  OUR_COMMITMENTS,
  OUR_INGREDIENTS,
  PLASTFREE_REVOLUTION,
  WHY_PLASTFREE,
} from "./productsActionType";

const initialState = {
  whyPlastfree: {},
  ourCommitments: {},
  ourIngredients: [],
  plastfreeRevolution: [],
};

const ProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case WHY_PLASTFREE: {
      return {
        ...state,
        whyPlastfree: action.payload,
      };
    }
    case OUR_COMMITMENTS: {
      return {
        ...state,
        ourCommitments: action.payload,
      };
    }
    case PLASTFREE_REVOLUTION: {
      return {
        ...state,
        plastfreeRevolution: action.payload,
      };
    }
    case OUR_INGREDIENTS: {
      return {
        ...state,
        ourIngredients: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ProductsReducer;
