import React from "react";
import "./formInput.css";
import propTypes from "prop-types";

const FormInput = props => {
  const { id, name } = props;

  return (
    <div className="form-input">
      <label htmlFor={id}>{name}</label>
      <input {...props} />
    </div>
  );
};

FormInput.propTypes = {
  type: propTypes.string,
  placeholder: propTypes.string,
  name: propTypes.string,
  id: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  required: propTypes.bool,
};

FormInput.defaultProps = {
  type: "text",
  placeholder: "",
  name: "",
  id: "",
  value: "",
  onChange: () => {},
  required: false,
};

export default FormInput;
