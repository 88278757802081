import {
  CERTIFICATES,
  CONTACT,
  FAQ,
  POLICY,
  PRESS,
  STATIC_MODAL,
  TERMS,
  FOUNDATION,
  GOAL,
  MISSION,
  STORY,
  BANNERS,
} from "./staticActionType";

export const addCertificates = certificates => ({
  type: CERTIFICATES,
  payload: certificates,
});

export const addContact = contactInfo => ({
  type: CONTACT,
  payload: contactInfo,
});

export const addFAQ = faq => ({
  type: FAQ,
  payload: faq,
});

export const addPress = press => ({
  type: PRESS,
  payload: press,
});

export const addTerms = terms => ({
  type: TERMS,
  payload: terms,
});

export const addPrivacy = privacy => ({
  type: POLICY,
  payload: privacy,
});

export const showStaticModal = (bool, content) => ({
  type: STATIC_MODAL,
  content,
  bool,
});

export const addGoal = goals => ({
  type: GOAL,
  payload: goals,
});

export const addMission = missions => ({
  type: MISSION,
  payload: missions,
});

export const addStory = story => ({
  type: STORY,
  payload: story,
});

export const addFoundation = foundation => ({
  type: FOUNDATION,
  payload: foundation,
});

export const addBanners = banners => ({
  type: BANNERS,
  payload: banners,
});
