import React from "react";
import "./contactTextArea.css";

const ContactTextArea = props => {
  const { id = "", name = "" } = props;

  return (
    <div className="contact-text-area">
      <label htmlFor={id}>{name}</label>
      <textarea rows={10} {...props} />
    </div>
  );
};

export default ContactTextArea;
