import React from "react";
import "./productBox.css";

const ProductBox = () => {
  return (
    <div className="product-box-container">
      <h2>TAMPON</h2>
      <span className="product-box-type">REGULAR</span>
      <span className="product-box-count">2 PACKS</span>
    </div>
  );
};

export default ProductBox;
