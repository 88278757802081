import React, { useState } from "react";
import "./productImageCarousel.css";
import { Carousel } from "react-responsive-carousel";
import ProductImg from "./../../../../Assets/one-off-product.png";

const ProductImageCarousel = () => {
  const images = [
    { src: ProductImg },
    { src: ProductImg },
    { src: ProductImg },
    { src: ProductImg },
    { src: ProductImg },
    { src: ProductImg },
  ];

  const [selectedItem, setSelectedItem] = useState(0);

  const carouselSettings = {
    showStatus: false,
    showArrows: false,
    showThumbs: false,
    showIndicators: false,
    selectedItem,
    autoPlay: false,
    onChange: i => setSelectedItem(i),
  };

  return (
    <div className="product-image-carousel-container">
      <Carousel {...carouselSettings}>
        {images.map(img => (
          <div>
            <img src={img.src} />
          </div>
        ))}
      </Carousel>
      <div className="product-image-carousel-thumbs">
        {images.map((img, index) => (
          <img
            onClick={() => setSelectedItem(index)}
            className={
              selectedItem === index ? "thumb-active" : "thumb-inactive"
            }
            src={img.src}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductImageCarousel;
