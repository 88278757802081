export const exploreLinks = [
  "About us",
  "Certifications",
  "Period Education",
  "Press/Events",
  "Contact",
  "Period Calculator",
  "Leave Feedback",
];

export const productLinks = [
  "Organic Liners",
  "Organic Pads",
  "Organic Tampons",
  "Organic Maternity",
  "Subscription Box",
  "Period Kit",
];

export const voucherLinks = [
  "Refer A Friend",
  "Supporting Local Business",
  "Stockist",
  "Student Discount",
  "Wholesale Enquiries",
];
