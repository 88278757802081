import React from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./miniCartPane.css";
import SVGIcons from "../../SVGIcons/SVGIcons";
import { cart } from "../../../data/dummyData";
import { CartItem } from "../CartItem";
import { PFButton } from "../PFButton";

const MiniCartPane = props => {
  const { show, setShow } = props;

  const configuration = {
    from: "right",
    width: "40%",
    isOpen: show,
    hideHeader: true,
    shouldCloseOnEsc: true,
    className: "mini-cart-pane",
  };

  return (
    <SlidingPane {...configuration}>
      <div className="cart-pane-main">
        <SVGIcons onClick={() => setShow(false)} icon={"xClose2"} />
        <div className="cart-title">
          <span>Cart</span>
          <span>({cart.length})</span>
        </div>
        <div>
          {cart.map(item => (
            <CartItem mini {...item} />
          ))}
        </div>
      </div>

      <div className="cart-pane-button-container">
        <PFButton text="view cart" />
        <PFButton text="checkout" />
      </div>
    </SlidingPane>
  );
};

export default MiniCartPane;
