import React from "react";
import { Header } from "../../../Shared/Components/Header";
import HomeBannerCarousel from "./Carousel";

const Banner = props => {
  const { setShow } = props;
  return (
    <section className="banner-container">
      <HomeBannerCarousel />
      <div className="home-header-wrap">
        <Header home setShow={setShow} />
      </div>
    </section>
  );
};

export default Banner;
