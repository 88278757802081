import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AboutUs } from "../Pages/Static/AboutUs";
import { AuthFormPage } from "../Pages/Auth";
import { Certifications } from "../Pages/Static/Certifications";
import { ContactUs } from "../Pages/Static/ContactUs";
import { Home } from "../Pages/Home";
import { Products } from "../Pages/Products";
import { Account } from "../Pages/Account";
import { FAQ } from "../Pages/Static/FAQ";
import { PressEvents } from "../Pages/Static/PressEvents";
import { Footer } from "../Shared/Components/Footer";
import { Header } from "../Shared/Components/Header";
import { MiniCartPane } from "../Shared/Components/MiniCartPane";
import { StaticModal } from "../Shared/Components/Modal";
import { OffHeader } from "../Shared/Components/OffHeader";
import { useDispatch } from "react-redux";
import { getBanners } from "../Shared/Services/staticPages";

const Routes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const notHome = pathname !== "/";

  const [cartPane, setCartPane] = useState(false);

  useEffect(() => {
    dispatch(getBanners());
  }, []);

  return (
    <div>
      {notHome && (
        <>
          <OffHeader />
          <Header setShow={setCartPane} />
        </>
      )}
      <Switch>
        <Route exact path="/">
          <Home setShow={setCartPane} />
        </Route>
        <Route path="/products" component={Products} />
        <Route path="/certifications" component={Certifications} />
        <Route path="/faq" component={FAQ} />
        <Route path="/press-events" component={PressEvents} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/sign-up" component={AuthFormPage} />
        <Route path="/sign-in" component={AuthFormPage} />
        <Route path="/reset-password" component={AuthFormPage} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/my-profile" component={Account} />
        <Route path="/my-orders" component={Account} />
      </Switch>
      <Footer />
      <MiniCartPane show={cartPane} setShow={setCartPane} />
      <StaticModal />
    </div>
  );
};

export default Routes;
