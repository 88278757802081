import React from "react";
import "./cartItem.css";

const CartItem = props => {
  const { mini, id, img, name, type, quantity, price } = props;

  return mini ? (
    <div className="cart-pane-item-container" key={id}>
      <div className="cart-pane-item">
        <div className="cart-pane-item-image">
          <img src={img} alt={`${name} ${type}`} />
        </div>
        <div className="cart-pane-item-details">
          <h2>{name}</h2>
          <div>
            <span>Type: {type}</span>
            <span>Quantity: {quantity}</span>
            <span>Price: {price}</span>
          </div>
        </div>
      </div>
      <div className="cart-pane-item-remove">
        <div>REMOVE</div>
      </div>
    </div>
  ) : null;
};

export default CartItem;
