import React from "react";
import SVGIcons from "../../SVGIcons/SVGIcons";
import "./quantityBox.css";

const QuantityBox = props => {
  const { quantity, selectedMonths, setSelectedMonths, product, offer } = props;

  const className =
    selectedMonths === quantity
      ? product
        ? "p-quantity-box"
        : "quantity-box"
      : product
      ? "p-quantity-box-inactive"
      : "quantity-box-inactive";

  const OfferStar = () => {
    return <SVGIcons icon="offer_star" />;
  };

  return (
    <div className="quantity-box-wrapper">
      <div onClick={() => setSelectedMonths(quantity)} className={className}>
        <span className="quantity">{quantity}</span>
        {offer && (
          <div className="offer-star">
            <OfferStar />
            <span className="offer-value">{offer}%</span>
          </div>
        )}
      </div>
      {!product && <span className="months-text">MONTHS</span>}
    </div>
  );
};

export default QuantityBox;
