import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./staticModal.css";
import Modal from "react-modal";
import SVGIcons from "../../SVGIcons/SVGIcons";
import { showStaticModal } from "../../Redux/StaticPages/staticActions";

const StaticModal = () => {
  Modal.setAppElement("#root");
  const dispatch = useDispatch();
  const { static_modal } = useSelector(state => state.static);
  const { bool, content } = static_modal;
  const { title = "", content: desc } = content;

  useEffect(() => {
    bool
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [bool]);

  const closeModal = () => {
    dispatch(showStaticModal(false, {}));
  };

  const ModalSettings = {
    isOpen: bool,
    contentLabel: "Static Modal",
    className: "static-modal-content",
    overlayClassName: "static-modal-overlay",
  };

  return (
    <Modal {...ModalSettings}>
      <div className="static-modal-header">
        <h1>{title}</h1>
        <SVGIcons onClick={closeModal} icon={"modalClose"} />
      </div>
      <div className="static-modal-main">
        <p>{desc}</p>
      </div>
    </Modal>
  );
};

export default StaticModal;
