import React from "react";
import { FormInput } from "../../../Shared/Components/FormInput";
import { PFButton } from "../../../Shared/Components/PFButton";
import { ContactTextArea } from "./ContactTextArea";
import "./contactUsFooter.css";

const ContactUsFooter = props => {
  const {
    address = "",
    email = "",
    headline1 = "",
    headline2 = "",
    hours = "",
    phone = "",
    sub_headline = "",
    sub_headline1 = "",
    sub_headline2 = "",
  } = props;

  const modifiedAddress = address.split(",");
  const modifiedHours = hours.split("\r\n");

  return (
    <div className="contact-us-footer">
      <div className="contact-us-footer-address-holder">
        <h3>{headline1}</h3>
        <address>
          <h4>{sub_headline}</h4>
          <div className="address-holder">
            {modifiedAddress.map(address => (
              <span>{address}</span>
            ))}
          </div>
        </address>
        <div className="contact-us-contact-info">
          <h3>{headline2}</h3>
          <h4>{sub_headline1}</h4>
          <span>Phone: {phone}</span>
          <span>Email: {email}</span>
        </div>
        <div className="contact-us-working-hours">
          <h4>{sub_headline2}</h4>
          {modifiedHours.map(hrs => (
            <span>{hrs}</span>
          ))}
        </div>
      </div>
      <form className="contact-us-footer-form">
        <FormInput
          type="text"
          placeholder="Your first name"
          name="First Name"
          id="firstName"
          required
        />
        <FormInput
          type="text"
          placeholder="Your last name"
          name="Last Name"
          id="lastName"
        />

        <FormInput
          type="email"
          placeholder="Your email"
          name="Email"
          id="email"
          required
        />
        <FormInput
          type="tel"
          placeholder="Your phone number"
          name="Phone Number"
          id="number"
          required
        />
        <ContactTextArea
          type="text"
          placeholder="Enter your message here"
          name="Message"
          id="contact-message"
          required
        />

        <div className="send-message-holder">
          <PFButton text={"Send Message"} />
        </div>
      </form>
    </div>
  );
};

export default ContactUsFooter;
