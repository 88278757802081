import React from "react";
import "./whyPlastFree.css";
import { useSelector } from "react-redux";

const WhyPlastFree = () => {
  const { whyPlastfree } = useSelector(state => state.products);
  const { title = "", content = "", image = "" } = whyPlastfree;

  return (
    <div className="why-container">
      <div className="why-img-container">
        <img src={image} alt={title} />
      </div>
      <div className="why-content-container">
        <div>
          <h2>WHY PLASTFREE</h2>
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default WhyPlastFree;
