export const STATIC_MODAL = "STATIC_MODAL";
export const CERTIFICATES = "CERTIFICATES";
export const CONTACT = "CONTACT";
export const FAQ = "FAQ";
export const PRESS = "PRESS";
export const TERMS = "TERMS";
export const POLICY = "POLICY";
export const GOAL = "GOAL";
export const MISSION = "MISSION";
export const STORY = "STORY";
export const FOUNDATION = "FOUNDATION";
export const BANNERS = "BANNERS";
