import React from "react";
import SVGIcons from "../../../../Shared/SVGIcons/SVGIcons";
import "./subscriptionProduct.css";

const SubscriptionProduct = props => {
  const { title } = props;

  return (
    <div className="subscription-product-container">
      <div className="sub-icon-container">
        <SVGIcons icon="sub_product_icon" />
      </div>
      <div className="sub-title-container">
        <span className="sub-product-title">{title}</span>
      </div>
      <div className="sub-product-add-button">
        <span>+</span>
      </div>
    </div>
  );
};

export default SubscriptionProduct;
