import React from "react";
import { useSelector } from "react-redux";
import "./banner.css";

const Banner = () => {
  const { banners } = useSelector(state => state.static);
  const aboutBanner = banners.find(e => e.title === "About");
  const { title, image } = aboutBanner !== undefined ? aboutBanner : {};

  return (
    <div className="about-banner-container">
      <img width="100%" height="auto" src={image} alt={title} />
    </div>
  );
};

export default Banner;
