import React from "react";
import { PANE_TYPES } from "../../Pages/Account/paneTypes";
import SVGIcons from "../../Shared/SVGIcons/SVGIcons";
import "./myProfile.css";

const MyProfile = ({ setActivePane }) => {
  return (
    <section className="my-profile-container">
      <div className="my-profile-title-container">
        <h1>My Profile</h1>
        <span onClick={() => setActivePane(PANE_TYPES.MY_PROFILE_EDIT)}>
          Edit
        </span>
      </div>
      <div className="my-profile-details">
        <div>
          <span className="my-profile-subtitle">Name</span>
          <span className="my-profile-sub-details">Pradeep</span>
        </div>
        <div>
          <span className="my-profile-subtitle">Address</span>
          <span className="my-profile-sub-details">
            <span className="address-title">Add a new address</span>{" "}
            <SVGIcons icon={"add_plus"} />
          </span>
        </div>
        <div>
          <span className="my-profile-subtitle">Email</span>
          <span className="my-profile-sub-details">
            pradeepanna24@gmail.com
          </span>
        </div>
      </div>
    </section>
  );
};

export default MyProfile;
