import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { showStaticModal } from "../../Redux/StaticPages/staticActions";
import SVGIcons from "../../SVGIcons/SVGIcons";
import "./footer.css";
import { exploreLinks, productLinks, voucherLinks } from "./footerLinks";
import SocialIcons from "./SocialIcons";
import {
  getPrivacyPolicy,
  getTermsAndConditions,
} from "../../Services/staticPages";

const Footer = () => {
  const dispatch = useDispatch();
  const { terms, privacy } = useSelector(state => state.static);

  useEffect(() => {
    dispatch(getTermsAndConditions());
    dispatch(getPrivacyPolicy());
  }, []);

  const FooterLinks = ({ links }) => {
    const history = useHistory();
    const navigate = path =>
      history.push(path.toLowerCase().replace(/\/| /g, "-"));

    return (
      <ul>
        {links.map(link => (
          <li onClick={() => navigate(link)}>{link}</li>
        ))}
      </ul>
    );
  };

  const openStaticModal = isTerms => {
    isTerms
      ? dispatch(showStaticModal(true, terms))
      : dispatch(showStaticModal(true, privacy));
  };

  return (
    <div className="footer-container">
      <div className="footer-top-content">
        <div className="logo-and-contents">
          <SVGIcons icon="homeLogo" />
          <p>
            Premium 100% certified organic cotton, eco-friendly & ultra
            absorbent period products at an affordable price!
          </p>
          <div className="social-icons-container">
            <SocialIcons />
          </div>
        </div>
        <div className="links">
          <div>
            <h3>Explore</h3>
            <FooterLinks links={exploreLinks} />
          </div>
          <div>
            <h3>Products</h3>
            <FooterLinks links={productLinks} />
          </div>
          <div>
            <h3>Vouchers</h3>
            <FooterLinks links={voucherLinks} />
          </div>
        </div>
        <div className="subscription">
          <h3>Subscribe</h3>
          <p>
            I would like to join the PlastFree revolution & am happy to receive
            info about products, offers, newsletters of PlastFree Foundation.
          </p>
          <div className="input-container">
            <input type="email" placeholder="Email Address" />
            <SVGIcons icon="rightArrow" />
          </div>
          <br />
          <span>Sign up to get 25% off your first order.</span>
        </div>
      </div>

      <div className="footer-bottom-content">
        <div>
          2021 © Plastfree Ltd. All Rights Reserved.{" "}
          <span onClick={() => openStaticModal(true)}>Terms & Conditions</span>{" "}
          | <span onClick={() => openStaticModal()}>Privacy Policy</span>
        </div>
        <SVGIcons icon="paymentIcons" />
        <span className="blaze-water-mark">
          Designed & Developed by BlazeDream.com
        </span>
      </div>
    </div>
  );
};

export default Footer;
