import React, { useState } from "react";
import { PFButton } from "../../../../Shared/Components/PFButton";
import { QuantityBox } from "../../../../Shared/Components/QuantityBox";
import { RatingStars } from "../../../../Shared/Components/RatingStars";
import SVGIcons from "../../../../Shared/SVGIcons/SVGIcons";
import "./productDetails.css";
import QuantityInput from "./QuantityInput";

const ProductDetails = () => {
  const [selectedQuantity, setSelectedQuantity] = useState(12);
  const [quantity, setQuantity] = useState("");

  const onQuantityInput = ({ target: { value } }) => {
    if (Number(value) < 50) {
      setQuantity(value);
    }
  };

  return (
    <div className="product-details-container">
      <div className="product-ratings-container">
        <RatingStars count={4} product />
        <span>(1,234 REVIEWS)</span>
      </div>
      <div className="product-title-container">
        <h2>Plastfree Organic Liners</h2>
      </div>
      <div></div>
      <div className="product-price-container">
        <span>£ 2.99</span>
        <span>PER PACK</span>
      </div>
      <p className="product-description">
        The use of Plastfree Daily liners helps to keep your vaginal area dry
        and free of perspiration. This in turn eliminates any odour. Plastfree
        liners are made of cotton giving you optimum dryness and comfort.
        Absorption is 10ml, Length is 155mm. Liners have biodegradable
        waterproof bottom layer, compostable release paper and an individual
        cotton wrapper.{" "}
      </p>
      <div className="product-quantity-boxes">
        <QuantityBox
          selectedMonths={selectedQuantity}
          setSelectedMonths={setSelectedQuantity}
          product
          quantity={1}
        />
        <QuantityBox
          selectedMonths={selectedQuantity}
          setSelectedMonths={setSelectedQuantity}
          product
          quantity={2}
        />
        <QuantityBox
          selectedMonths={selectedQuantity}
          setSelectedMonths={setSelectedQuantity}
          product
          offer={15}
          quantity={12}
        />
        <QuantityInput value={quantity} onChange={onQuantityInput} name="QTY" />
      </div>
      <div className="add-to-bag-btn">
        <PFButton text="add to bag" />
      </div>
      <div className="product-benefits-container">
        <div>
          <SVGIcons icon="round_tick" />
          <span className="product-benefit">Kinder to the skin</span>
        </div>
        <div>
          <SVGIcons icon="round_tick" />
          <span className="product-benefit">Kinder to your planet</span>
        </div>
        <div>
          <SVGIcons icon="round_tick" />
          <span className="product-benefit">Live consciously</span>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
