import React from "react";
import { useSelector } from "react-redux";
import "./pressMain.css";

const PressMain = ({ mainRef }) => {
  const { press = [] } = useSelector(state => state.static);

  console.log(press);

  return (
    <div ref={mainRef} className="press-main-container">
      {press.map(
        article =>
          article.content?.length > 0 && (
            <div className="press-content">
              <span
                className="press-image-holder"
                dangerouslySetInnerHTML={{ __html: article.image }}
              />
              <span className="press-timestamp">april 01, 20201</span>
              <span className="press-title">{article.content}</span>
              <span className="press-read-more">read more</span>
            </div>
          )
      )}
    </div>
  );
};

export default PressMain;
