import React, { useState } from "react";
import { FormInput } from "../../Shared/Components/FormInput";
import { PFButton } from "../../Shared/Components/PFButton";
import "./authForm.css";

const ResetForm = () => {
  const [email, setEmail] = useState("");

  const onInputChange = ({ target: { value } }) => {
    setEmail(value);
  };

  return (
    <form className="pf-form">
      <div className="reset-password-heading-desc">
        <h2>
          We will send you an email to reset your password. Check your spam
          folder if you don’t see it right away.
        </h2>
      </div>
      <FormInput
        type="email"
        placeholder="Your email"
        name="Email"
        id="email"
        value={email}
        onChange={onInputChange}
        required
      />

      <aside className="sign-in-button-row">
        <PFButton text="RESET PASSWORD" />
        <div>
          <span>Cancel</span>
        </div>
      </aside>
    </form>
  );
};

export default ResetForm;
