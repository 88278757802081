import React, { useState } from "react";
import "./NewsLogos.css";
import { useSelector } from "react-redux";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const NewsLogos = () => {
  const { media } = useSelector(state => state.home);
  const [hover, onHover] = useState(null);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const onMouseEnter = id => {
    onHover(id);
  };

  const onMouseLeave = () => {
    onHover(null);
  };

  return (
    <div className="news-logos-container">
      <MultiCarousel
        responsive={responsive}
        autoPlay
        infinite
        customTransition="transform 12s"
        transitionDuration={12000}
        arrows={false}
        autoPlay
        autoPlaySpeed={0}
        itemClass="logo-item"
      >
        {media.map(logo => (
          <div key={logo.id} className="logo-item">
            <span
              onMouseEnter={() => onMouseEnter(logo.id)}
              onMouseLeave={onMouseLeave}
              dangerouslySetInnerHTML={{
                __html: hover === logo.id ? logo.color_image : logo.image,
              }}
            />
          </div>
        ))}
      </MultiCarousel>
    </div>
  );
};

export default NewsLogos;
