import React from "react";
import { useSelector } from "react-redux";
import "./OurPartners.css";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OurPartners = () => {
  const { partners } = useSelector(state => state.home);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="partners-container">
      <h1>PARTNERS</h1>
      <MultiCarousel
        responsive={responsive}
        autoPlay
        infinite
        customTransition="transform 12s"
        transitionDuration={12000}
        arrows={false}
        autoPlay
        autoPlaySpeed={0}
        itemClass="partner-content"
      >
        {partners.map(partner => (
          <div key={partner.id} className="partner-content">
            <span dangerouslySetInnerHTML={{ __html: partner.image }} />
          </div>
        ))}
      </MultiCarousel>
    </div>
  );
};

export default OurPartners;
