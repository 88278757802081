import React from "react";
import "./leftPane.css";
import SVGIcons from "../../Shared/SVGIcons/SVGIcons";
import { PANE_TYPES } from "../../Pages/Account/paneTypes";
import { useHistory, useLocation } from "react-router-dom";

const LeftPane = ({ setActivePane }) => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname.replace("/", "");

  const navigate = (path, type) => {
    history.push(`/${path}`);
    setActivePane(type);
  };

  return (
    <aside className="left-pane-container">
      <h1>Hi John</h1>
      <div>
        <span
          onClick={() => navigate("my-profile", PANE_TYPES.MY_PROFILE)}
          className={
            path === "my-profile" ? "active-pane" : "pane-section-title"
          }
        >
          My profile
        </span>
        <SVGIcons icon="right_chevron" />
      </div>
      <div>
        <span
          onClick={() => navigate("my-orders", PANE_TYPES.ORDER_HISTORY)}
          className={
            path === "my-orders" ? "active-pane" : "pane-section-title"
          }
        >
          Order History
        </span>
        <SVGIcons icon="right_chevron" />
      </div>{" "}
      <div>
        <span className="pane-section-title">Logout</span>
        <SVGIcons icon="right_chevron" />
      </div>
    </aside>
  );
};

export default LeftPane;
