import axios from "axios";

axios.defaults.baseURL = "http://134.195.138.15/plastfree/wp-json/";

const CONSUMER_KEY = "ck_c10c31c28f564647c22162315ade3151acf71a2a";
const CONSUMER_SECRET = "cs_5614e6116f33c979dedb5b7a076178e24a37ad65";

export const WpVersion = { v1: "api/v1", v3: "wc/v3" };

export const PfGET = async (path, params = {}) => {
  axios.defaults.params = params;
  try {
    const response = await axios.get(path);
    const { data, status } = response;
    if (status === 200) {
      return data;
    }
  } catch (error) {
    if (error.response) {
      return { message: "Invalid Request" };
    } else if (error.request) {
      return { message: "No Response" };
    }
    return { message: error.message };
  }
};

export const PfPOST = async (path, data) => {
  try {
    const response = await axios.post(path, data);
    const { data: responseData, status } = response;
    if (status === 200 || status === 201) {
      return responseData;
    }
  } catch (error) {
    if (error.response) {
      return { message: "Invalid Request" };
    } else if (error.request) {
      return { message: "No Response" };
    }
    return { message: error.message };
  }
};
