import {
  addBanners,
  addCertificates,
  addContact,
  addFAQ,
  addFoundation,
  addGoal,
  addMission,
  addPress,
  addPrivacy,
  addStory,
  addTerms,
} from "../Redux/StaticPages/staticActions";
import { PfGET, WpVersion } from "./APIConfig";

export const getStory = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/ourstory`);
    const story = data[0];
    dispatch(addStory(story));
  };
};

export const getFoundation = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/plastfreefoundation`);
    dispatch(addFoundation(data));
  };
};

export const getGoals = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/ourgoal`);
    dispatch(addGoal(data));
  };
};

export const getMissions = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/ourmission`);
    dispatch(addMission(data));
  };
};

export const getCertificates = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/certificates`);
    dispatch(addCertificates(data));
  };
};

export const getContactInfo = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/contacts`);
    const contact = data[0];
    dispatch(addContact(contact));
  };
};

export const getFAQ = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/questions`);
    dispatch(addFAQ(data));
  };
};

export const getPressEvents = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/press`);
    dispatch(addPress(data));
  };
};

export const getTermsAndConditions = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/conditions`);
    const obj = data[0];
    dispatch(addTerms(obj));
  };
};

export const getPrivacyPolicy = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/privacy`);
    const obj = data[0];
    dispatch(addPrivacy(obj));
  };
};

export const getBanners = () => {
  return async dispatch => {
    const homeBanner = await PfGET(`${WpVersion.v1}/homebanner`);
    const data = await PfGET(`${WpVersion.v1}/banners`);
    const newData = [...data, { homeBanner }];
    dispatch(addBanners(newData));
  };
};
