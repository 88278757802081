import React from "react";
import BlogCarousel from "./BlogCarousel";
import "./OurBlog.css";

const OurBlog = () => {
  return (
    <div>
      <BlogCarousel />
    </div>
  );
};

export default OurBlog;
