import {
  addOurCommitments,
  addOurIngredients,
  addPlastfreeRevolution,
  addWhyPlastfree,
} from "../Redux/Products/productsActions";
import { PfGET, WpVersion } from "./APIConfig";

export const getWhyPlastfree = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/whyplastfree`);
    const obj = data[0];
    dispatch(addWhyPlastfree(obj));
  };
};

export const getOurCommitments = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/productcommitment`);
    const obj = data.length > 0 ? data[0] : {};
    dispatch(addOurCommitments(obj));
  };
};

export const getPlastfreeRevolution = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/plastfreerevolution`);
    dispatch(addPlastfreeRevolution(data));
  };
};

export const getOurIngredients = () => {
  return async dispatch => {
    const data = await PfGET(`${WpVersion.v1}/ouringredients`);
    dispatch(addOurIngredients(data));
  };
};
