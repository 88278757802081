import React, { useState } from "react";
import { PFButton } from "../../../Shared/Components/PFButton";
import { QuantityBox } from "../../../Shared/Components/QuantityBox";
import SVGIcons from "../../../Shared/SVGIcons/SVGIcons";
import { CustomBox } from "./CustomBox";
import { ProductBox } from "./ProductBox";
import "./subscriptionBox.css";
import { SubscriptionProduct } from "./SubscriptionProduct";

const SubscriptionBox = () => {
  const products = [
    { title: "LINER" },
    { title: "TAMPON" },
    { title: "PAD" },
    { title: "PAD" },
  ];

  const [selectedMonths, setSelectedMonths] = useState(6);

  return (
    <section className="subscription-box-container">
      <h1>Subscription Box</h1>
      <div className="subscription-box-description-container">
        <div>
          <SVGIcons icon="sub_desc_mark" />
          <span className="sub-desc">
            Each product can have maximum upto 4 packs
          </span>
        </div>
        <div>
          <SVGIcons icon="sub_desc_mark" />
          <span className="sub-desc">
            Every 50th order gets a FREE GIFT, will you be next ?
          </span>
        </div>
        <div>
          <SVGIcons icon="sub_soap" />
          <span className="sub-desc-2">soap</span>
          <span className="sub-desc-plus">+</span>
          <SVGIcons icon="sub_bag" />
          <span className="sub-desc-2">bag</span>
          <span className="sub-desc-plus">+</span>
          <SVGIcons icon="sub_face_mask" />
          <span className="sub-desc-2">mask</span>
        </div>
      </div>
      <div className="subscription-box-main">
        <div className="subscription-products-container">
          {products.map(product => (
            <SubscriptionProduct title={product.title} />
          ))}
        </div>
        <div className="subscription-boxes-container">
          {products.map(product => (
            <ProductBox />
          ))}
        </div>
        <div className="subscription-cart-container">
          <div>
            <CustomBox products={products} />
            <div className="quantity-box-container">
              <QuantityBox
                selectedMonths={selectedMonths}
                setSelectedMonths={setSelectedMonths}
                quantity={1}
              />
              <QuantityBox
                selectedMonths={selectedMonths}
                setSelectedMonths={setSelectedMonths}
                quantity={3}
              />
              <QuantityBox
                selectedMonths={selectedMonths}
                setSelectedMonths={setSelectedMonths}
                quantity={6}
              />
            </div>
            <div className="subscribe-button">
              <PFButton text={"Subscribe"} />
            </div>
            <div>
              <PFButton text={"ONE-OFF PURCHASE"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionBox;
