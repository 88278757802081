import React, { useState } from "react";
import { useHistory } from "react-router";
import { FormInput } from "../../Shared/Components/FormInput";
import { PFButton } from "../../Shared/Components/PFButton";
import "./authForm.css";

const SignInForm = () => {
  const history = useHistory();

  const [signInForm, setSignInForm] = useState({
    email: "",
    password: "",
  });

  const onInputChange = event => {
    const {
      target: { id, value },
    } = event;
    id in signInForm && setSignInForm({ ...signInForm, [id]: value });
  };

  const navigate = path => history.push(`/${path}`);

  return (
    <form className="pf-form">
      <FormInput
        type="text"
        placeholder="Your email"
        name="Email"
        id="email"
        value={signInForm.email}
        onChange={onInputChange}
        required
      />

      <FormInput
        type="password"
        placeholder="Your password"
        name="Password"
        id="password"
        value={signInForm.password}
        onChange={onInputChange}
        required
      />

      <aside className="sign-in-button-row">
        <PFButton text="SIGN IN" />
        <div>
          <span onClick={() => navigate("reset-password")}>
            Forgot Password?
          </span>
        </div>
      </aside>
      <aside className="bottom-prompt">
        <span>New Customer?</span>
        <span onClick={() => navigate("sign-up")}>Create Account</span>
      </aside>
    </form>
  );
};

export default SignInForm;
