import CartImage from "../Assets/cartimage.png";

export const cart = [
  {
    id: 0,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 1,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 2,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 3,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 4,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 5,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 6,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 7,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
  {
    id: 8,
    img: CartImage,
    name: "Plastfree Organic Liners",
    type: "Liner",
    quantity: "1",
    price: "£ 2.99",
  },
];
