import React, { useState } from "react";
import { useHistory } from "react-router";
import { FormInput } from "../../Shared/Components/FormInput";
import { PFButton } from "../../Shared/Components/PFButton";
import { createAccount } from "../../Shared/Services/auth";
import { getSanitizedUserName } from "../../Utils/regex";
import "./authForm.css";
import { validateRegForm } from "./validate";

const SignUpForm = () => {
  const history = useHistory();

  const [regForm, setRegForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    acceptTerms: false,
  });

  const onInputChange = event => {
    const { target } = event;
    // console.log(validateRegForm(target));
    const { id, value, checked } = target;
    id in regForm && id === "acceptTerms"
      ? setRegForm({ ...regForm, [id]: checked })
      : setRegForm({ ...regForm, [id]: value });
  };

  const navigate = path => history.push(`/${path}`);

  const signUp = () => {
    const username = getSanitizedUserName(regForm.email);
    const customerData = { ...regForm, username };
    createAccount(customerData);
  };

  return (
    <form className="pf-form">
      <FormInput
        type="text"
        placeholder="Your first name"
        name="First Name"
        id="first_name"
        value={regForm.first_name}
        onChange={onInputChange}
        required
      />

      <FormInput
        type="text"
        placeholder="Your last name"
        name="Last Name"
        id="last_name"
        value={regForm.last_name}
        onChange={onInputChange}
      />

      <FormInput
        type="email"
        placeholder="Your email"
        name="Email"
        id="email"
        value={regForm.email}
        onChange={onInputChange}
        required
      />

      <FormInput
        type="password"
        placeholder="Your password"
        name="Password"
        id="password"
        value={regForm.password}
        onChange={onInputChange}
        required
      />

      <div>
        <input
          type="checkbox"
          checked={regForm.acceptTerms}
          name="Terms Acceptance"
          id="acceptTerms"
          onChange={onInputChange}
          required
        />
        <label htmlFor="acceptTerms" className="terms-acceptance-text">
          By signing up for an account you accept our Terms & Privacy Policy.
        </label>
      </div>
      <div>
        <PFButton onClick={signUp} text="SIGN UP" />
      </div>
      <div className="bottom-prompt">
        <span>Existing Customer?</span>
        <span onClick={() => navigate("sign-in")}>Sign in</span>
      </div>
    </form>
  );
};

export default SignUpForm;
