import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./foundation.css";

const Foundation = () => {
  const { foundation } = useSelector(state => state.static);
  const title = foundation[foundation.length - 1]?.title;
  const content = foundation[foundation.length - 1]?.content;

  return (
    <div className="foundation-container">
      <h3>{title}</h3>
      <div className="foundation-desc-container">
        <p>{content}</p>
      </div>
      <div className="foundation-icons-container">
        {foundation
          .filter(e => e.title.includes("Image"))
          .map(foundation => (
            <img
              className="foundation-icon"
              alt={foundation.title}
              src={foundation.image}
            />
          ))}
      </div>
    </div>
  );
};

export default Foundation;
