import React from "react";
import { PANE_TYPES } from "../../Pages/Account/paneTypes";
import "./orderHistory.css";

const OrderHistory = ({ setActivePane }) => {
  return (
    <section className="order-history-container">
      <h1>Order History</h1>
      <table className="order-history-table">
        <tr>
          <th>Order Placed</th>
          <th>Order #</th>
          <th>Total</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        <tr>
          <td>01 May 2021</td>
          <td>9876542</td>
          <td>£ 3.99</td>
          <td>Processing</td>
          <td>
            <span>View</span>
          </td>
        </tr>
        <tr>
          <td>01 May 2021</td>
          <td>9876542</td>
          <td>£ 3.99</td>
          <td>Delivered</td>
          <td>
            <span>View</span>
          </td>
        </tr>
        <tr>
          <td>01 May 2021</td>
          <td>9876542</td>
          <td>£ 3.99</td>
          <td>Cancelled</td>
          <td>
            <span>View</span>
          </td>
        </tr>
        <tr>
          <td>01 May 2021</td>
          <td>9876542</td>
          <td>£ 3.99</td>
          <td>Delivered</td>
          <td>
            <span onClick={() => setActivePane(PANE_TYPES.ORDER_DETAILS)}>
              View
            </span>
          </td>
        </tr>
      </table>
    </section>
  );
};

export default OrderHistory;
