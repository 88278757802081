import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Ambassador } from "../../Components/Home/Ambassador";
import Banner from "../../Components/Home/Banner/Banner";
import { MonthlyBox } from "../../Components/Home/MonthlyBox";
import { NewsLogos } from "../../Components/Home/NewsLogos";
import { OurBlog } from "../../Components/Home/OurBlog";
import { OurCommitment } from "../../Components/Home/OurCommitment";
import { OurPartners } from "../../Components/Home/OurPartners";
import { PFStatement } from "../../Components/Home/PlastFreeStatement";
import { Testimonials } from "../../Components/Home/Testimonials";
import { Header } from "../../Shared/Components/Header";
import { MiniCartPane } from "../../Shared/Components/MiniCartPane";
import { OffHeader } from "../../Shared/Components/OffHeader";
import {
  getAmbassador,
  getCommitments,
  getMediaPartners,
  getPartners,
  getStatement,
  getTestimonials,
} from "../../Shared/Services/home";

// Landing Page

const Home = props => {
  const dispatch = useDispatch();
  const [cartPane, setCartPane] = useState(false);

  useEffect(() => {
    dispatch(getMediaPartners());
    dispatch(getStatement());
    dispatch(getTestimonials());
    dispatch(getAmbassador());
    dispatch(getPartners());
    dispatch(getCommitments());
  }, []);

  const HomeStickyHeader = () => {
    const [offset, setOffset] = useState(0);
    useEffect(() => {
      const unsubscribe = (window.onscroll = () => {
        setOffset(window.pageYOffset);
      });
      return unsubscribe;
    }, []);

    return offset > 130 && <Header setShow={setCartPane} />;
  };

  const { setShow } = props;
  return (
    <div>
      <OffHeader />
      <HomeStickyHeader />
      <Banner setShow={setShow} />
      <NewsLogos />
      <PFStatement />
      <OurCommitment />
      <MonthlyBox />
      <Ambassador />
      <OurPartners />
      <OurBlog />
      <Testimonials />
      <MiniCartPane show={cartPane} setShow={setCartPane} />
    </div>
  );
};

export default Home;
