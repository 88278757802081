import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContactUsBanner from "../../../Components/Static/ContactUs/ContactUsBanner";
import ContactUsFooter from "../../../Components/Static/ContactUs/ContactUsFooter";
import ContactUsMain from "../../../Components/Static/ContactUs/ContactUsMain";
import { getContactInfo } from "../../../Shared/Services/staticPages";

const ContactUs = () => {
  const mainRef = useRef();
  const dispatch = useDispatch();
  const { contact } = useSelector(state => state.static);

  useEffect(() => {
    dispatch(getContactInfo());
  }, []);

  return (
    <div>
      <ContactUsBanner mainRef={mainRef} />
      <ContactUsMain {...contact} mainRef={mainRef} />
      <ContactUsFooter {...contact} />
    </div>
  );
};

export default ContactUs;
