import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ProductBanner } from "../../Components/Products/Banner";
import { Commitment } from "../../Components/Products/Commitment";
import { Ingredients } from "../../Components/Products/Ingredients";
import { OneOffPurchase } from "../../Components/Products/OneOffPurchase";
import { PlastFreeStats } from "../../Components/Products/PlastFreeStats";
import { SubscriptionBox } from "../../Components/Products/SubscriptionBox";
import { TrialPack } from "../../Components/Products/TrialPack";
import { WhyPlastFree } from "../../Components/Products/WhyPlastFree";
import {
  getOurCommitments,
  getOurIngredients,
  getPlastfreeRevolution,
  getWhyPlastfree,
} from "../../Shared/Services/products";
import "./products.css";

const Products = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWhyPlastfree());
    dispatch(getOurCommitments());
    dispatch(getPlastfreeRevolution());
    dispatch(getOurIngredients());
  }, []);

  return (
    <div>
      <ProductBanner />
      <TrialPack />
      <OneOffPurchase />
      <SubscriptionBox />
      <WhyPlastFree />
      <Commitment />
      <PlastFreeStats />
      <Ingredients />
    </div>
  );
};

export default Products;
