import React from "react";
import { PFButton } from "../../../Shared/Components/PFButton";
import "./trialPack.css";
import Image from "../../../Assets/tp.png";

const TrialPack = () => {
  return (
    <section className="trial-pack-container">
      <div className="trial-pack-content-container">
        <div>
          <div className="trial-pack-content">
            <h3>Trial Pack for £ 1.99</h3>
            <span>FREE DELIVERY</span>
          </div>
          <div className="button-container">
            <PFButton text="SHOP TRIAL PACK" />
          </div>
        </div>
      </div>
      <div className="trial-pack-image-container">
        <img src={Image} />
      </div>
    </section>
  );
};

export default TrialPack;
