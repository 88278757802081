import React from "react";
import "./PFButton.css";
import propTypes from "prop-types";

const PFButton = ({ text, borderLess, onClick }) => {
  const className = borderLess
    ? "pfbutton-container-border-less"
    : "pfbutton-container";

  return (
    <div onClick={onClick} className={className}>
      {text}
    </div>
  );
};

PFButton.propTypes = {
  text: propTypes.string,
};

PFButton.defaultProps = {
  text: "",
};

export default PFButton;
