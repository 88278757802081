import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Banner.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SVGIcons from "../../../Shared/SVGIcons/SVGIcons";
import { PFButton } from "../../../Shared/Components/PFButton";

const HomeBannerCarousel = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const { banners } = useSelector(state => state.static);
  const homeBannerList = banners.find(e => e.homeBanner);
  let { homeBanner = [] } = homeBannerList !== undefined ? homeBannerList : {};
  const carouselSettings = {
    showStatus: false,
    showArrows: false,
    showThumbs: false,
    showIndicators: false,
    selectedItem,
    autoPlay: true,
    infiniteLoop: true,
    onChange: i => setSelectedItem(i),
  };

  const CarouselIndicators = () => {
    const changeSelectedItem = i => {
      setSelectedItem(i);
    };

    return homeBanner.map((_, i) => (
      <div className="carousel-indicator" onClick={() => changeSelectedItem(i)}>
        <SVGIcons
          icon={selectedItem === i ? "ellipsisOpen" : "ellipsisClose"}
        />
      </div>
    ));
  };

  return (
    <div className="carousel-container">
      <Carousel {...carouselSettings}>
        {homeBanner.map(media => (
          <div className="carousel-item" key={media.id}>
            <img src={media.gif_image} alt={media.title} />
            <div className="carousel-content">
              <h1>{media.title}</h1>
              <PFButton text={media.button} />
            </div>
          </div>
        ))}
      </Carousel>
      <div className="carousel-indicators-container">
        <CarouselIndicators />
      </div>
    </div>
  );
};

export default HomeBannerCarousel;
