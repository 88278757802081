import React from "react";
import "./MonthlyBox.css";
import { PFButton } from "../../../Shared/Components/PFButton";
import { useSelector } from "react-redux";

const MonthlyBox = () => {
  const { banners } = useSelector(state => state.static);

  const monthlyBox = banners.find(v => v.id === 3261);
  const {
    button = "",
    description = "",
    image = "",
    title = "",
  } = monthlyBox !== undefined ? monthlyBox : {};

  return (
    <div className="monthly-box-container">
      <img className="monthly-box-bg" src={image} />
      <div className="monthly-box-content">
        <h2>{title}</h2>
        <h1>{description}</h1>
        <p>Choose your tampons or pads based on your flow.</p>
        <div className="shop-btn-container">
          <PFButton text={button} />
        </div>
      </div>
    </div>
  );
};

export default MonthlyBox;
