import React from "react";
import { CustomBoxItem } from "../CustomBoxItem";
import "./customBox.css";

const CustomBox = props => {
  const { products } = props;

  return (
    <div className="custom-box-container">
      <h2>Your Own Custom Box</h2>
      <div className="custom-box-product-container">
        {products.map(product => (
          <CustomBoxItem {...product} />
        ))}
      </div>
      <div className="custom-cart-total-container">
        <span>£ 20.98</span>
        <span>8 PACKS</span>
      </div>
    </div>
  );
};

export default CustomBox;
