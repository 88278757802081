export const RoundRightArrow = ({ small }) => {
  const width = small ? "36" : "48";
  const height = small ? "36" : "48";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="23.5" fill="#FFFAF1" stroke="#CEB4AD" />
      <path
        d="M21.5 17.5L27.5001 23.5L21.5 29.5"
        stroke="#602714"
        stroke-width="2"
      />
    </svg>
  );
};

export const RoundLeftArrow = ({ small }) => {
  const width = small ? "36" : "48";
  const height = small ? "36" : "48";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r="23.5"
        transform="matrix(-1 0 0 1 24 24)"
        fill="#FFFAF1"
        stroke="#CEB4AD"
      />
      <path
        d="M26.5 17.5L20.4999 23.5L26.5 29.5"
        stroke="#602714"
        stroke-width="2"
      />
    </svg>
  );
};
