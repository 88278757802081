import {
  OUR_COMMITMENTS,
  OUR_INGREDIENTS,
  PLASTFREE_REVOLUTION,
  WHY_PLASTFREE,
} from "./productsActionType";

export const addWhyPlastfree = content => ({
  type: WHY_PLASTFREE,
  payload: content,
});

export const addOurCommitments = content => ({
  type: OUR_COMMITMENTS,
  payload: content,
});

export const addPlastfreeRevolution = content => ({
  type: PLASTFREE_REVOLUTION,
  payload: content,
});

export const addOurIngredients = ingredients => ({
  type: OUR_INGREDIENTS,
  payload: ingredients,
});
