import React from "react";
import "./commitment.css";
import { useSelector } from "react-redux";

const Commitment = () => {
  const { ourCommitments } = useSelector(state => state.products);
  const { title = "", content = "", image = "" } = ourCommitments;

  return (
    <div className="products-commitment-container">
      <div className="products-commitment-content">
        <div>
          <h2>OUR COMMITMENT</h2>
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
      </div>
      <div className="products-commitment-img-container">
        <img src={image} alt={title} />
      </div>
    </div>
  );
};

export default Commitment;
