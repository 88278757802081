import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { PressBanner, PressMain } from "../../../Components/Static/PressEvents";
import { getPressEvents } from "../../../Shared/Services/staticPages";
import "./pressEvents.css";

const PressEvents = () => {
  const dispatch = useDispatch();
  const mainRef = useRef();

  useEffect(() => {
    dispatch(getPressEvents());
  }, []);

  return (
    <div>
      <PressBanner mainRef={mainRef} />
      <PressMain mainRef={mainRef} />
    </div>
  );
};

export default PressEvents;
