import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { CertBanner } from "../../../Components/Static/Certifications/Banner";
import { CertMain } from "../../../Components/Static/Certifications/Main";
import { PadDifference } from "../../../Components/Static/Certifications/PadDifference";
import { getCertificates } from "../../../Shared/Services/staticPages";

const Certifications = () => {
  const dispatch = useDispatch();
  const mainRef = useRef();

  useEffect(() => {
    dispatch(getCertificates());
  }, []);

  return (
    <div>
      <CertBanner mainRef={mainRef} />
      <CertMain mainRef={mainRef} />
      <PadDifference />
    </div>
  );
};

export default Certifications;
