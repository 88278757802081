import {
  COMMITMENTS,
  MEDIA_PARTNERS,
  PARTNERS,
  PLASTFREE_AMBASSADOR,
  PLASTFREE_STATEMENT,
  TESTIMONIALS,
} from "./homeActionTypes";

export const addMediaPartners = media => ({
  type: MEDIA_PARTNERS,
  payload: media,
});

export const addPlastfreeStatement = statement => ({
  type: PLASTFREE_STATEMENT,
  payload: statement,
});

export const addTestimonials = testimonials => ({
  type: TESTIMONIALS,
  payload: testimonials,
});

export const addAmbassador = ambassador => ({
  type: PLASTFREE_AMBASSADOR,
  payload: ambassador,
});

export const addCommitments = commitments => ({
  type: COMMITMENTS,
  payload: commitments,
});

export const addPartners = partners => ({
  type: PARTNERS,
  payload: partners,
});
