import React from "react";
import { useSelector } from "react-redux";
import SVGIcons from "../../../Shared/SVGIcons/SVGIcons";
import "./pressBanner.css";

const PressBanner = ({ mainRef }) => {
  const { banners } = useSelector(state => state.static);
  const Banner = banners.find(e => e.title === "Press");
  const { title, image, description } = Banner !== undefined ? Banner : {};

  const scrollToMain = () => {
    mainRef.current.scrollIntoView();
  };
  return (
    <div className="press-banner-container">
      <img src={image} alt={title} />
      <div className="press-banner-content">
        <h1>{title}</h1>
        <p>{description}</p>
        <SVGIcons onClick={scrollToMain} icon="down_chevron" />
      </div>
    </div>
  );
};

export default PressBanner;
