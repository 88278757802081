import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { PFButton } from "../../../Shared/Components/PFButton";
import SVGIcons from "../../../Shared/SVGIcons/SVGIcons";
import { blogData } from "./blogdata";
import "./OurBlog.css";

const BlogCarousel = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  const carouselSettings = {
    showStatus: false,
    showArrows: false,
    showThumbs: false,
    showIndicators: false,
    selectedItem,
    autoPlay: true,
    infiniteLoop: true,
    onChange: i => setSelectedItem(i),
  };

  const CarouselIndicators = () => {
    const changeSelectedItem = i => {
      setSelectedItem(i);
    };

    return blogData.map((_, i) => (
      <div
        className="blog-carousel-indicator"
        onClick={() => changeSelectedItem(i)}
      >
        <SVGIcons
          icon={selectedItem === i ? "ellipsisOpen" : "ellipsisClose"}
        />
      </div>
    ));
  };

  return (
    <div className="blog-carousel-container">
      <Carousel {...carouselSettings}>
        {blogData.map((content, i) => (
          <div className="blog-carousel-item" key={content.id}>
            <div className="blog-carousel-cover-image-container">
              <img
                className="blog-carousel-cover-image"
                src={content.image}
                alt={"plaster-banner" + i}
              />
              <div className="blog-carousel-indicators-container">
                <CarouselIndicators />
              </div>
            </div>
            <div className="blog-carousel-content-container">
              <div className="blog-carousel-content">
                <h2>BLOG</h2>
                <h1>{content.title}</h1>
                <p>{content.desc}</p>
                <div className="learn-more-btn-container">
                  <PFButton text={"LEARN MORE"} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default BlogCarousel;
