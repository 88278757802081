import React from "react";
import { useHistory } from "react-router";
import SVGIcons from "../../SVGIcons/SVGIcons";
import "./Header.css";
import NavMenu from "./NavMenu";

const Header = props => {
  const history = useHistory();
  const { home, setShow } = props;

  const navigate = path => history.push(path);

  return (
    <section
      className={home ? "header-container-transparent" : "header-container"}
    >
      <div className="logo-container">
        <SVGIcons
          onClick={() => navigate("/")}
          icon={home ? "homeLogo" : "logo"}
        />
      </div>
      <NavMenu home={home} />
      <div className="account-bag-container">
        <div className="account-logo-wrap">
          <SVGIcons
            onClick={() => navigate("/sign-in")}
            icon={home ? "whiteAccountLogo" : "accountLogo"}
          />
        </div>
        <SVGIcons
          onClick={() => setShow(true)}
          icon={home ? "whiteCartBag" : "cartBag"}
        />
        <span
          onClick={() => setShow(true)}
          className={home ? "cart-bag-count-white" : "cart-bag-count"}
        >
          (0)
        </span>
      </div>
    </section>
  );
};

export default Header;
