import React from "react";
import { useSelector } from "react-redux";
import "./certificationsBanner.css";
import SVGIcons from "../../../../Shared/SVGIcons/SVGIcons";

const CertificationsBanner = ({ mainRef }) => {
  const { banners } = useSelector(state => state.static);
  const Banner = banners.find(e => e.title === "Certificates");
  const { title, image, description } = Banner !== undefined ? Banner : {};

  const scrollToMain = () => {
    mainRef.current.scrollIntoView();
  };

  return (
    <div className="certifications-banner-container">
      <img src={image} alt={title} />
      <div className="certifications-banner-content">
        <h1>{"Certifications"}</h1>
        <p>{description}</p>
        <SVGIcons onClick={scrollToMain} icon="down_chevron" />
      </div>
    </div>
  );
};

export default CertificationsBanner;
