import React from "react";
import { useSelector } from "react-redux";
import "./mission.css";

const Mission = () => {
  const { missions } = useSelector(state => state.static);

  return (
    <div className="mission-container">
      <h3>Our Mission</h3>
      <div className="missions">
        {missions.map(mission => (
          <div key={mission.id} className="mission">
            <img src={mission.image} />
            <h5>{mission.title}</h5>
            <p>{mission.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Mission;
