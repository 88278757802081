import React from "react";
import { useSelector } from "react-redux";
import "./banner.css";

const Banner = () => {
  const { banners } = useSelector(state => state.static);

  const productBanner = banners.find(e => e.title === "Products");
  const { title, image } = productBanner !== undefined ? productBanner : {};

  return (
    <div className="product-banner-container">
      <img src={image} width={"100%"} height={"auto"} alt={title} />
    </div>
  );
};

export default Banner;
