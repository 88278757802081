import React from "react";
import "./ratingStars.css";
import Star from "./Star";

const RatingStars = ({ count, product }) => {
  const filledColor = product ? "#F9C2AB" : "#de572b";
  return (
    <div title={count} className="rating-stars">
      {Array(5)
        .fill("")
        .map((_, index) => (
          <Star filled={index + 1 <= count} filledColor={filledColor} />
        ))}
    </div>
  );
};

export default RatingStars;
