import React from "react";
import propTypes from "prop-types";

const QuantityInput = props => {
  const { id, name } = props;

  return (
    <div className="product-quantity-input">
      <label htmlFor={id}>{name}</label>
      <input {...props} />
    </div>
  );
};

QuantityInput.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  id: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  maxlength: propTypes.number,
};

QuantityInput.defaultProps = {
  type: "text",
  name: "",
  id: "",
  value: "",
  maxlength: 3,
  onChange: () => {},
};

export default QuantityInput;
