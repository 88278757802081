import React from "react";
import "./ambassador.css";
import { useSelector } from "react-redux";
import { PFButton } from "../../../Shared/Components/PFButton";

const Ambassador = () => {
  const { ambassador } = useSelector(state => state.home);
  const { content = "", title = "", image = "" } = ambassador;

  return (
    <div className="ambassador-container">
      <div className="ambassador-content">
        <h2>{title}</h2>
        <p>“{content}”</p>
        <h1>BHASHA MUKHERJEE</h1>
        <br />
        <span>MISS ENGLAND 2019-21, NHS DOCTOR</span>
        <div className="ambassador-btn-container">
          <PFButton text="ASK US ANYTHING" />
        </div>
      </div>
      <div className="ambassador-image-container">
        <img src={image} />
      </div>
    </div>
  );
};

export default Ambassador;
