import React from "react";
import { PANE_TYPES } from "../../Pages/Account/paneTypes";
import "./orderDetails.css";

const OrderDetails = ({ setActivePane }) => {
  const OrderBillBox = () => {
    return (
      <div className="order-bill-box">
        <table className="order-bill-box-1">
          <tr>
            <td>subtotal</td>
            <td>£ 331.89</td>
          </tr>
          <tr>
            <td>shipping</td>
            <td>£ 25.89</td>
          </tr>
          <tr>
            <td>tax</td>
            <td>£ 0.00</td>
          </tr>
          <tr>
            <td>discount</td>
            <td>£ 20.00</td>
          </tr>
        </table>
        <div className="order-detail-separator" />
        <table className="order-bill-box-2">
          <tr>
            <td>grand total</td>
            <td>£ 1562.27</td>
          </tr>
        </table>
        <div className="order-detail-separator" />
      </div>
    );
  };

  return (
    <section className="order-details-container">
      <div className="order-details-title-holder">
        <h1>Order Details</h1>
        <span onClick={() => setActivePane(PANE_TYPES.ORDER_HISTORY)}>
          back
        </span>
      </div>
      <div className="order-info-holder">
        <span>ORDERED ON</span>
        <span>01 May 2021</span>
        <span>ORDER #</span> <span>975445</span>
      </div>
      <table className="order-details-table">
        <tr>
          <th>Product</th>
          <th>Type</th>
          <th>Price</th>
          <th>Qty</th>
          <th>Total</th>
        </tr>
        <tr>
          <td>Plastfree Liners</td>
          <td>Liners</td>
          <td>£ 3.99</td>
          <td>2</td>
          <td>£ 7.98</td>
        </tr>
        <tr>
          <td>Plastfree Liners</td>
          <td>Liners</td>
          <td>£ 3.99</td>
          <td>2</td>
          <td>£ 7.98</td>
        </tr>
        <tr>
          <td>Plastfree Liners</td>
          <td>Liners</td>
          <td>£ 3.99</td>
          <td>2</td>
          <td>£ 7.98</td>
        </tr>
        <tr>
          <td>Plastfree Liners</td>
          <td>Liners</td>
          <td>£ 3.99</td>
          <td>2</td>
          <td>£ 7.98</td>
        </tr>
      </table>
      <OrderBillBox />
    </section>
  );
};

export default OrderDetails;
